import React, { FunctionComponent } from "react";

import Layout from "../common/layout/layout";
import SEO from "../common/seo";
import Navigation from "../common/navigation";

const AboutPage: FunctionComponent = () => (
  <Layout>
    <SEO title="About" />
    <Navigation
      desktopHalfWidth
      links={[
        { url: "/about/critiques/", text: "Critiques" },
        { url: "/about/biography/", text: "Biography" },
      ]}
    />
  </Layout>
);

export default AboutPage;
