import React, { FunctionComponent } from "react";
import { Link } from "gatsby";

import * as classes from "./navigation.module.scss";

interface NavigationItem {
  url: string;
  text: string;
}

interface NavigationProps {
  links: NavigationItem[];
  compact?: boolean;
  desktopHalfWidth?: boolean;
}

const Navigation: FunctionComponent<NavigationProps> = ({
  links,
  compact = false,
  desktopHalfWidth = false,
}) => (
  <ul
    className={desktopHalfWidth ? `${classes.nav} lg-half-width` : classes.nav}
  >
    {links.map((link, i) => (
      <li key={i} className={compact ? classes.compact : ""}>
        <Link to={link.url}>{link.text}</Link>
      </li>
    ))}
  </ul>
);

export default Navigation;
